<template>
  <div class="coverNewPassword">
    <el-row type="flex" justify="space-around" style="margin-top: 4px">
      <el-col :sm="6">
        <el-card class="box-card">
          <div slot="header" style="text-align: center">
            <img
              src="<%= htmlWebpackPlugin.files.publicPath %>../../static/img/monofactura2.svg"
              style="height: 200px"
            />
          </div>
          <el-alert
            title="Ingresa una nueva contraseña"
            type="info"
            :closable="false"
          ></el-alert>
          <el-form
            ref="formSecurity"
            :model="userSecurity"
            :rules="rulesSecurity"
            :status-icon="true"
          >
            <el-form-item :label="$t('password')" prop="passwordNew">
              <el-input
                v-model="userSecurity.passwordNew"
                type="password"
                :maxlength="32"
                auto-complete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('confirmPassword')"
              prop="passwordNewCheck"
            >
              <el-input
                v-model="userSecurity.passwordNewCheck"
                type="password"
                :maxlength="32"
                auto-complete="off"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                size="mini"
                type="primary"
                :disabled="!recoveryIsValid"
                :loading="loading"
                @click.prevent="updatePassword"
              >
                {{ $t('changePassword') }}
              </el-button>
              <el-button size="mini" type="text" @click.prevent="login">
                {{ $t('login') }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { i18n } from '../../i18n'

import router from '../../router'

// import axios from 'axios'
// import uuid from 'uuid'
export default {
  components: {},
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Ingresa la nueva contraseña'))
      } else {
        if (this.userSecurity.passwordNewCheck !== '') {
          this.$refs.formSecurity.validateField('passwordNewCheck')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Ingresa la contraseña nuevamente'))
      } else if (value !== this.userSecurity.passwordNew) {
        callback(new Error('La contraseña no es igual!'))
      } else {
        callback()
      }
    }

    return {
      userSecurity: {
        userId: '',
        passwordCurrent: '',
        passwordNew: '',
        passwordNewCheck: '',
      },
      loading: false,
      recoveryIsValid: false,
      rulesSecurity: {
        passwordCurrent: [
          { required: true, message: 'El campo es requerido', trigger: 'blur' },
        ],
        passwordNew: [{ validator: validatePass, trigger: 'blur' }],
        passwordNewCheck: [{ validator: validatePass2, trigger: 'blur' }],
      },
    }
  },
  mounted() {
    const _this = this
    _this.get()
  },
  methods: {
    get() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(
          `users/${_this.$route.params.id}/validateRecovery/?code=${_this.$route.params.code}`,
        )
        .then(
          response => {
            _this.userSecurity.userId = _this.$route.params.id
            _this.recoveryIsValid = true
            _this.loading = false
          },
          response => {
            _this.loading = false
          },
        )
    },
    login() {
      router.push({ name: 'Login' })
    },
    updatePassword() {
      const _this = this
      _this.$refs.formSecurity.validate(valid => {
        if (valid) {
          _this.loadingForm = true

          // _this.userSecurity.userId = _this.user.id
          _this.$http
            .post('users/UpdatePasswordFromUser', _this.userSecurity)
            .then(
              response => {
                _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')

                // _this.$message({ showClose: true, message: i18n.t('processSuccess'), type: 'success' })
                _this.loadingForm = false
                _this.$refs.formSecurity.resetFields()
              },
              response => {
                _this.loadingForm = false
              },
            )
        }
      })
    },
  },
}
</script>
